/* About Section Background */
:root {
  --primary-color: #6C3CE9;
  --text-dark: #1A1A1A;
  --text-light: #666;
  --white: #fff;
  --gradient-bg: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(238, 232, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
  --card-gradient: linear-gradient(71deg, #324864, #1e3b57, #0c2a42);
  --transition: all 0.3s ease;

  --mission-color: #FF5733; /* Red */
  --vision-color: #33FF57; /* Green */
  --work-color: #3357FF; /* Blue */
}
.about-background {
    background:linear-gradient(to right, #17263c, #1e3a5f);
    padding: 5rem 2rem;
    width: 100vw; /* Full viewport width */
    margin-left: calc(-50vw + 50%);
    position: relative; /* Ensure full-width background */
  }
  
  .about-section {
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 20px;
  }
  
  .gradient-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
  }
  
  .about-item {
    position: relative;
    background: var(--card-gradient);
    border-radius: 20px;
    padding: 2.5rem 2rem;
    color: var(--white);
    transition: var(--transition);
    box-shadow: 0 4px 20px inear-gradient(to right, #17263c, #1e3a5f)
  }
  
  .about-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(108, 60, 233, 0.2);
  }
  
  .about-item.mission {
    border-left: 5px solid var(--mission-color);
  }
  
  .about-item.vision {
    border-left: 5px solid var(--vision-color);
  }
  
  .about-item.work {
    border-left: 5px solid var(--work-color);
  }
  
  .about-item i {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .about-item.mission i {
    color: var(--mission-color);
  }
  
  .about-item.vision i {
    color: var(--vision-color);
  }
  
  .about-item.work i {
    color: var(--work-color);
  }
  
  .about-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--white);
  }
  
  .about-item p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
  }
  