:root {
  --primary-color: #6C3CE9;
  --text-dark: #1A1A1A;
  --text-light: #666;
  --white: #fff;
  --mission-color: #FF5733;
  --vision-color: #33FF57;
  --work-color: #3357FF;
  --deep-blue: #0B1222;
  --star-color: #ffffff;
  --grid-color: rgba(33, 150, 243, 0.1);
  --line-color: rgba(33, 150, 243, 0.4);
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: var(--deep-blue);
  line-height: 1.6;
  color: var(--text-dark);
}

.contact-space-background {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0B1222, #1a237e);
  overflow: hidden;
}

.contact-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: 
    radial-gradient(2px 2px at 20px 30px, #eee, rgba(0,0,0,0)), 
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 50px 160px, #ddd, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0,0,0,0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: twinkle 4s ease-in-out infinite;
}

.contact-grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(90deg, var(--grid-color) 1px, transparent 1px),
    linear-gradient(0deg, var(--grid-color) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: center center;
  transform: perspective(500px) rotateX(60deg);
  transform-origin: center top;
}

.contact-gradient-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contact-line {
  position: absolute;
  width: 200%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%, 
    var(--line-color) 15%, 
    var(--line-color) 50%, 
    var(--line-color) 85%, 
    transparent 100%);
  animation: contact-moveLine 8s linear infinite;
}

.contact-line-1 { top: 20%; animation-delay: 0s; }
.contact-line-2 { top: 40%; animation-delay: -2s; }
.contact-line-3 { top: 60%; animation-delay: -4s; }
.contact-line-4 { top: 80%; animation-delay: -6s; }
.contact-line-5 { top: 90%; animation-delay: -8s; }

@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

@keyframes contact-moveLine {
  from { transform: translateX(-50%); }
  to { transform: translateX(0); }
}

.contact-container {
  position: relative;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  gap: 2rem;
  margin-top: 70px;
}

.contact-card {
  max-width: 1200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-header .contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact-header .contact-subtitle {
  color: var(--text-light);
  line-height: 1.5;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-item .contact-icon {
  background: #e6f0ff;
  padding: 0.75rem;
  border-radius: 50%;
  font-size: 1.25rem;
}

.contact-item .contact-label {
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #000;
}

.contact-item .contact-value {
  color: var(--text-light);
}

.contact-social-links {
  margin-top: auto;
}

.contact-social-title {
  font-weight: 500;
  margin-bottom: 1rem;
  color: #000;
}

.contact-social-icons {
  display: flex;
  gap: 1rem;
}

.contact-social-icon {
  background: none;
  border: 2px solid #3b82f6;
  padding: 0.5rem;
  font-size: 2rem;
  color: #1e3a8a;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s, color 0.3s;
}

.contact-social-icon:hover {
  background: #3b82f6;
  color: white;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact-form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
}

.contact-form-group input,
.contact-form-group textarea {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.contact-form-group input:focus,
.contact-form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
}

.contact-submit-button {
  background: #3b82f6;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-submit-button:hover {
  background: #2563eb;
}

.contact-map-container {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.98);
}

.contact-map-container iframe {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 450px;
}

@media (min-width: 768px) {
  .contact-card {
    grid-template-columns: 1fr 1fr;
  }
}