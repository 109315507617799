:root {
  --primary-color: #6C3CE9;
  --text-dark: #1A1A1A;
  --text-light: #666;
  --white: #fff;
  --mission-color: #FF5733; 
  --vision-color: #33FF57; 
  --work-color: #3357FF;
  --deep-blue: #0B1222;
  --star-color: #ffffff;
  --grid-color: rgba(33, 150, 243, 0.1);
  --line-color: rgba(33, 150, 243, 0.4);
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: var(--deep-blue);
  line-height: 1.6;
  color: var(--text-dark);
}

/* Space Background Styles */
.space-background {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0B1222, #1a237e);
  overflow: hidden;
}

/* Stars Effect */
.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: 
    radial-gradient(2px 2px at 20px 30px, #eee, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 50px 160px, #ddd, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0,0,0,0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: twinkle 4s ease-in-out infinite;
}

/* Grid Overlay */
.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(90deg, rgba(33, 150, 243, 0.1) 1px, transparent 1px),
    linear-gradient(0deg, rgba(33, 150, 243, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: center center;
  transform: perspective(500px) rotateX(60deg);
  transform-origin: center top;
}

/* Gradient Lines */
.gradient-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.line {
  position: absolute;
  width: 200%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%,
    rgba(33, 150, 243, 0.2) 15%,
    rgba(33, 150, 243, 0.4) 50%,
    rgba(33, 150, 243, 0.2) 85%,
    transparent 100%
  );
  animation: moveLine 8s linear infinite;
}

.line-1 { top: 20%; animation-delay: 0s; }
.line-2 { top: 40%; animation-delay: -2s; }
.line-3 { top: 60%; animation-delay: -4s; }
.line-4 { top: 80%; animation-delay: -6s; }
.line-5 { top: 90%; animation-delay: -8s; }

/* Content Wrapper */


/* Hero Section Styles */
.hero {
  text-align: center;
  padding: 6rem 1rem;
  color: white;
}

.hero h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(33, 150, 243, 0.5);
}

.hero p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

/* Animations */
@keyframes twinkle {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}

@keyframes moveLine {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0%); }
}



/* Responsive Adjustments */
@media (max-width: 768px) {
  .grid-overlay {
    transform: perspective(300px) rotateX(60deg);
  }
  
  .hero {
    padding: 4rem 1rem;
  }
}
