/* Modern Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.navbar-scrolled {
  background: rgba(255, 255, 255, 1); /* Solid white when scrolled */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.navbar-logo img {
  height: 50px; /* Increased height for better visibility */
  width: auto;
  transition: transform 0.3s ease;
}

.navbar-logo:hover img {
  transform: scale(1.1);
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.navbar-toggle-icon {
  width: 25px;
  height: 2px;
  background: #333;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-toggle-icon::before,
.navbar-toggle-icon::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 2px;
  background: #333;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-toggle-icon::before {
  transform: translateY(-6px);
}

.navbar-toggle-icon::after {
  transform: translateY(6px);
}

.navbar-toggle.open .navbar-toggle-icon {
  transform: rotate(45deg);
}

.navbar-toggle.open .navbar-toggle-icon::before {
  transform: rotate(90deg);
}

.navbar-toggle.open .navbar-toggle-icon::after {
  opacity: 0;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 20px;
}

.nav-item a {
  text-decoration: none;
  color: #9CA3AF; /* Default color before hover */
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  transition: color 0.3s ease, background 0.3s ease;
}

.nav-item a:hover {
  color: #007bff; /* Blue hover effect */
}

.nav-item a.active {
  color: #007bff; /* Blue for active link */
  font-weight: 600;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
  }

  .navbar-menu {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .navbar-menu.open {
    transform: translateX(0);
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-item a {
    font-size: 18px;
  }
}