.service-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.top-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.overlay-text {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.service-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin-top: 20px;
  gap: 20px;
  position: relative;
}

.service-image {
  flex: 1;
}

.service-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  animation: slide-in-left 1.5s ease forwards;
}

.service-description {
  flex: 2;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: slide-in-right 1.5s ease forwards;
}

.service-not-found {
  text-align: center;
  padding: 50px;
}

.service-not-found a {
  color: #007bff;
  text-decoration: underline;
}

.related-services-scroll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
}

.service-card {
  flex: 1 0 30%; /* Flex basis for larger screens */
  max-width: 300px; /* Max width on larger screens */
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out;
  box-sizing: border-box;
}

.service-card img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 5px;
}

.show-cards .service-card {
  animation: slide-in 1s ease forwards;
}

.dot-navigation {
  text-align: center;
  margin-top: 20px;
}

.dot-navigation .dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot-navigation .dot.active {
  background-color: #333;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .service-description {
    width: 100%;
  }
  .service-card {
    flex: 0 0 80%; /* Set width to 80% on medium screens */
    max-width: none;
  }
}

@media (max-width: 480px) {
  .service-card {
    flex: 0 0 100%; /* Full width on very small screens */
    max-width: none;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
