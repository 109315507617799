/* Loader.css */
.modern-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  overflow: hidden;
}

.particles-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.loader-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  transform: rotate(-90deg);
  filter: drop-shadow(0 0 10px #007bff); /* Blue glow effect */
}

.progress-ring__circle-bg,
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform-origin: 50% 50%;
}

.progress-ring__circle-bg {
  opacity: 0.1;
}

.progress-ring__circle {
  stroke-linecap: round;
}

.progress-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.progress-number {
  display: flex;
  align-items: baseline;
  color: #fff;
  font-family: 'Inter', sans-serif;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.number {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
}

.percent {
  font-size: 1.5rem;
  margin-left: 0.25rem;
  opacity: 0.8;
}

.completion-message {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #fff;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s ease forwards;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Glowing effect */
.progress-ring__circle {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    filter: drop-shadow(0 0 2px rgba(109, 40, 217, 0.5))
            drop-shadow(0 0 4px rgba(109, 40, 217, 0.3));
  }
  to {
    filter: drop-shadow(0 0 4px #007bff)
            drop-shadow(0 0 8px #007bff);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .progress-ring {
    width: 200px;
    height: 200px;
  }

  .number {
    font-size: 3rem;
  }

  .percent {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .progress-ring {
    width: 160px;
    height: 160px;
  }

  .number {
    font-size: 2.5rem;
  }

  .percent {
    font-size: 1rem;
  }
}