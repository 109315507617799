/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: #f6f6f6;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
/* New class for the section title with black color and border */
.section-title-black-border {
  color: black !important; /* Ensures the text is black */
  border: 2px solid black;
  padding: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 24px; /* Adjust as needed */
  border-radius: 8px; /* Optional: add rounded corners */
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-image {
  float: left;
  margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}

/* Loading State */
.loading {
  font-size: 18px;
  color: #999;
  font-style: italic;
}

/* Optional: Uncomment for Hover Effects */
.testimonial:hover {
  background: #e6e6e6;
  transform: translateY(-5px);
  transition: transform 0.3s ease, background 0.3s ease;
}
