/* General Reset for Navbar */
.navbar {
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: transparent; /* Changed from black to transparent */
  color: #fff;
}

/* Reset body margin and padding */
body {
  margin: 0;
  padding: 0;
}

/* Services Section */
#services {
  padding: 0; /* Removed top padding */
  background: linear-gradient(to right, #17263c, #1e3a5f);
  color: #fff; /* Changed all text to white */
  position: relative;
}

#services .service-desc {
  margin: 10px 10px 20px;
}

#services h2 {
  color: #fff; /* Text color to white */
}

#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff; /* Text color to white */
}

#services p {
  color: #fff; /* Text color to white */
}

#services .service-desc {
  margin-bottom: 40px;
}

/* Image Container */
.image-container {
  position: relative;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  top: 0;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  top: 0;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  padding: 30px;
}

.image-overlay h1 {
  font-size: 2.5em;
  margin-bottom: 15px;
}

.image-overlay p {
  font-size: 1.2em;
  color: white; /* Updated to white */
  text-decoration-color: white; /* Ensure text decoration is also white */
}

/* Card Styling */
.service-card {
  background-color: #324864; /* Changed to the new color */
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3,
.service-card p {
  color: #fff; /* Update text color for better contrast */
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Grid Layout */
#services .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#services .col-md-4, #services .col-sm-6 {
  flex: 0 1 30%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Responsive Scaling */
@media (max-width: 992px) {
  #services .col-md-4, #services .col-sm-6 {
    transform: scale(0.9);
  }
}

@media (max-width: 768px) {
  #services .col-md-4, #services .col-sm-6 {
    transform: scale(0.8);
  }
}

@media (max-width: 576px) {
  #services .col-md-4, #services .col-sm-6 {
    transform: scale(0.7);
  }
}

/* Button Styling */
button {
  padding: 12px 24px;
  background-color:#3b82f6 ;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

button:hover {
  background-color: #5ca9fb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

button:active {
  background-color: #1e7e34;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.white-text {
  color: white; /* Class to make text white */
}
