/* Add this new class for the paragraph title in black specific to the features section with a border */
.features-title-black {
  color: black !important;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  padding: 10px;
  border: 2px solid black;
  border-radius: 8px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.features-title-black:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 1);
}

/* Features Section */
#features {
  background: #e0e4e8; /* Updated background color */
  padding: 80px 0;
  overflow: hidden;
}

.section-title {
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1200px;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  padding: 20px 0;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel:active {
  cursor: grabbing;
}

.feature-card {
  flex: 0 0 300px;
  margin: 0 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-content {
  background: linear-gradient(to right, #2c3e50, #4ca1af);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  color: white;
}

.feature-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.feature-image:hover {
  transform: scale(1.05);
}

.feature-image.hidden {
  display: none;
}

.feature-image.visible {
  display: block;
}

/* Blue Bubbles Loader scoped to Features */
#features .feature-loader {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

#features .feature-loader .bubble {
  width: 12px;
  height: 12px;
  background-color: #1e90ff;
  border-radius: 50%;
  animation: bubble 0.6s ease-in-out infinite alternate;
}

#features .feature-loader .bubble:nth-child(2) {
  animation-delay: 0.2s;
}

#features .feature-loader .bubble:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bubble {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-12px);
  }
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
}

.feature-content h3,
.feature-content p {
  color: white;
  text-align: center;
}