.careers-section {
    padding: 20px;
  }
  
  .careers-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .careers-section h3 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .careers-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .careers-section li {
    margin-bottom: 15px;
  }
  
  .careers-section a {
    color: blue;
    text-decoration: underline;
  }
  