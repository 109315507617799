/* Footer styling */
.footer {
  background: rgba(13, 17, 27, 1);
  color: #9CA3AF;
  padding: 40px 0;
  position: relative;
  width: 100%;
  bottom: 0;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.footer-section {
  flex: 1 1 200px; /* Adjusts section width */
  margin: 10px;
  display: flex;
  flex-direction: column;
}

/* Logo styling */
.footer-section img.footer-logo {
  width: 120px; /* Adjust size as needed */
  margin-bottom: 15px;
  align-self: flex-start;
}

/* General Text Color */
.footer-section p, 
.footer-section ul li a {
  color: #9CA3AF; /* text-gray-400 */
}

/* Heading color */
.footer-section h4 {
  color: #3b82f6; /* Set the heading color */
  font-weight: 600; /* font-semibold */
}

/* Specific Links Color */
.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a:hover {
  color: #3b82f6; /* Change link color on hover */
  text-decoration: underline;
}

.footer-section p {
  margin: 0;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons li {
  list-style: none;
}

.social-icons li a {
  color: #fff;
  font-size: 16px;
}

.footer-bottom {
  text-align: center; /* Centers the copyright text */
  margin-top: 20px;
  width: 100%;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}
