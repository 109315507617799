.team {
  background: linear-gradient(45deg, #005889, #00739b, #00061e); /* Gradient background color */
  padding: 60px 0;
}

.section-title {
  text-align: center; /* Center-align the text */
  margin-bottom: 50px; /* Add some space below the section title */
}

.section-title h2 {
  font-size: 36px;
  font-weight: bold;
  color: #fff; /* Adjust the color as needed */
  margin-bottom: 20px; /* Add some space below the heading */
}

.section-title p {
  font-size: 18px;
  color: #ddd; /* Adjust the color as needed */
  max-width: 600px; /* Limit the width of the paragraph */
  margin: 0 auto; /* Center the paragraph */
}

/* Rest of your CSS */
.our-team {
  text-align: center;
  margin-bottom: 100px;
  z-index: 1;
  position: relative;
}

.our-team .pic {
  border-radius: 50%;
  overflow: hidden;
  width: 200px; /* Adjust the width to your desired size */
  height: 200px; /* Ensure height is equal to width for a perfect circle */
  margin: 0 auto; /* Center the image */
  position: relative;
}

.our-team .pic:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .pic:after { opacity: 1; }

.our-team .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the element without stretching */
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the icons */
  z-index: 2; /* Ensure the icons are on top */
  transition: opacity 0.5s ease;
}

.our-team:hover .social { 
  opacity: 1; 
}

.our-team .social li { 
  display: inline-block; 
}

.our-team .social li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  font-size: 15px;
  color: #fff;
  margin-right: 10px;
  transition: all 0.5s ease 0s;
}

.our-team .social li a:hover {
  background: #fff;
  color: #000;
}

.our-team .team-content {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px dotted #ddd;
  position: absolute;
  bottom: -70px;
  left: 0;
  z-index: -1;
  transition: all 0.5s ease 0s;
}

.our-team:hover .team-content { border: 2px dotted #00adae; }

.our-team .team-info {
  width: 100%;
  color: #464646;
  position: absolute;
  bottom: 12px;
  left: 0;
  background: #fff; /* Add white background */
  padding: 10px; /* Add padding for better spacing */
  border-radius: 10px; /* Add some border-radius for a smoother look */
}

.our-team .title {
  font-size: 20px;
  font-weight: 600;
  color: #464646;
  margin: 10px 0 5px 0;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #999;
}
