.header-hero {
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  text-align: center;
}

.header-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  max-width: 900px;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.header-brand {
  font-size: 2.5rem;
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.header-brand-text {
  color: #ffffff;
}

.header-brand-highlight {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-left: 10px;
}

.header-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 1.2;
}

.header-rotating-text {
  display: inline-block;
  margin-left: 15px;
  font-weight: 800;
  position: relative;
}

.header-description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.header-cta-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.header-cta-primary,
.header-cta-secondary {
  padding: 15px 35px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header-cta-primary {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 163, 255, 0.3);
}

.header-cta-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 163, 255, 0.4);
}

.header-cta-secondary {
  background: transparent;
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
}

.header-cta-secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;
  }
  
  .header-brand {
    font-size: 2rem;
  }
  
  .header-description {
    font-size: 1.1rem;
    padding: 0 20px;
  }
  
  .header-cta-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .header-cta-primary,
  .header-cta-secondary {
    width: 100%;
    max-width: 280px;
  }
}