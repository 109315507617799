.specialized-tech-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1a1f2e 0%, #2a3444 100%);
  min-height: 100vh;
  text-align: center;
}

.section-title {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: slideDown 0.8s ease forwards;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

.tech-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.15);
}

.card-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  z-index: 2;
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.tech-card:hover .card-icon {
  transform: scale(1.2);
}

.card-image-container {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-card:hover .card-image {
  transform: scale(1.1);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.tech-card:hover .card-overlay {
  opacity: 1;
}

.stats-container {
  padding: 1rem;
  color: white;
  width: 100%;
  transform: translateY(20px);
  transition: transform 0.4s ease;
}

.tech-card:hover .stats-container {
  transform: translateY(0);
}

.stat {
  margin-bottom: 1rem;
}

.stat-label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #a0aec0;
}

.stat-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
}

.stat-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  margin: 0.5rem 0;
  overflow: hidden;
}

.stat-fill {
  height: 100%;
  background: linear-gradient(90deg, #3b82f6 0%, #60a5fa 100%);
  border-radius: 2px;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-card:hover .stat-fill {
  transform: scaleX(1);
}

.card-title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.card-description {
  color: #a0aec0;
  font-size: 1rem;
  line-height: 1.5;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom AOS animations */
[data-aos="custom-fade-up"] {
  opacity: 0;
  transform: translateY(30px);
  transition-property: transform, opacity;
}

[data-aos="custom-fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .tech-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }

  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .specialized-tech-section {
    padding: 2rem 1rem;
  }

  .tech-grid {
    grid-template-columns: 1fr;
  }

  .card-title {
    font-size: 1.25rem;
  }
}

/* Add smooth scrolling to the whole page */
html {
  scroll-behavior: smooth;
}