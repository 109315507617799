@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Modern Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.navbar-scrolled {
  background: rgba(255, 255, 255, 1); /* Solid white when scrolled */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.navbar-logo img {
  height: 50px; /* Increased height for better visibility */
  width: auto;
  transition: transform 0.3s ease;
}

.navbar-logo:hover img {
  transform: scale(1.1);
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.navbar-toggle-icon {
  width: 25px;
  height: 2px;
  background: #333;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-toggle-icon::before,
.navbar-toggle-icon::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 2px;
  background: #333;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-toggle-icon::before {
  transform: translateY(-6px);
}

.navbar-toggle-icon::after {
  transform: translateY(6px);
}

.navbar-toggle.open .navbar-toggle-icon {
  transform: rotate(45deg);
}

.navbar-toggle.open .navbar-toggle-icon::before {
  transform: rotate(90deg);
}

.navbar-toggle.open .navbar-toggle-icon::after {
  opacity: 0;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 20px;
}

.nav-item a {
  text-decoration: none;
  color: #9CA3AF; /* Default color before hover */
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  transition: color 0.3s ease, background 0.3s ease;
}

.nav-item a:hover {
  color: #007bff; /* Blue hover effect */
}

.nav-item a.active {
  color: #007bff; /* Blue for active link */
  font-weight: 600;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
  }

  .navbar-menu {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.98);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .navbar-menu.open {
    transform: translateX(0);
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-item a {
    font-size: 18px;
  }
}
@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

/* Add this to your App.css or a separate CSS file */

.fade-enter {
  opacity: 0;
  transform: translateY(20px); /* Move down when entering */
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
  transition: opacity 300ms ease, transform 300ms ease; /* Adjust timing */
}

.fade-exit {
  opacity: 1;
  transform: translateY(0); /* Stay in place when exiting */
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px); /* Move up when exiting */
  transition: opacity 300ms ease, transform 300ms ease; /* Adjust timing */
}

/* Loader.css */
.modern-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  overflow: hidden;
}

.particles-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.loader-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  transform: rotate(-90deg);
  filter: drop-shadow(0 0 10px #007bff); /* Blue glow effect */
}

.progress-ring__circle-bg,
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform-origin: 50% 50%;
}

.progress-ring__circle-bg {
  opacity: 0.1;
}

.progress-ring__circle {
  stroke-linecap: round;
}

.progress-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.progress-number {
  display: flex;
  align-items: baseline;
  color: #fff;
  font-family: 'Inter', sans-serif;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.number {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
}

.percent {
  font-size: 1.5rem;
  margin-left: 0.25rem;
  opacity: 0.8;
}

.completion-message {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #fff;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s ease forwards;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Glowing effect */
.progress-ring__circle {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    filter: drop-shadow(0 0 2px rgba(109, 40, 217, 0.5))
            drop-shadow(0 0 4px rgba(109, 40, 217, 0.3));
  }
  to {
    filter: drop-shadow(0 0 4px #007bff)
            drop-shadow(0 0 8px #007bff);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .progress-ring {
    width: 200px;
    height: 200px;
  }

  .number {
    font-size: 3rem;
  }

  .percent {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .progress-ring {
    width: 160px;
    height: 160px;
  }

  .number {
    font-size: 2.5rem;
  }

  .percent {
    font-size: 1rem;
  }
}
.header-hero {
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  text-align: center;
}

.header-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  max-width: 900px;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.03);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.header-brand {
  font-size: 2.5rem;
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.header-brand-text {
  color: #ffffff;
}

.header-brand-highlight {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-left: 10px;
}

.header-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 1.2;
}

.header-rotating-text {
  display: inline-block;
  margin-left: 15px;
  font-weight: 800;
  position: relative;
}

.header-description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.header-cta-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.header-cta-primary,
.header-cta-secondary {
  padding: 15px 35px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header-cta-primary {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 163, 255, 0.3);
}

.header-cta-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 163, 255, 0.4);
}

.header-cta-secondary {
  background: transparent;
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.header-cta-secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;
  }
  
  .header-brand {
    font-size: 2rem;
  }
  
  .header-description {
    font-size: 1.1rem;
    padding: 0 20px;
  }
  
  .header-cta-container {
    flex-direction: column;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .header-cta-primary,
  .header-cta-secondary {
    width: 100%;
    max-width: 280px;
  }
}
/* Add this new class for the paragraph title in black specific to the features section with a border */
.features-title-black {
  color: black !important;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  padding: 10px;
  border: 2px solid black;
  border-radius: 8px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.features-title-black:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 1);
}

/* Features Section */
#features {
  background: #e0e4e8; /* Updated background color */
  padding: 80px 0;
  overflow: hidden;
}

.section-title {
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1200px;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  padding: 20px 0;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel:active {
  cursor: grabbing;
}

.feature-card {
  flex: 0 0 300px;
  margin: 0 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-content {
  background: linear-gradient(to right, #2c3e50, #4ca1af);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  color: white;
}

.feature-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.feature-image:hover {
  transform: scale(1.05);
}

.feature-image.hidden {
  display: none;
}

.feature-image.visible {
  display: block;
}

/* Blue Bubbles Loader scoped to Features */
#features .feature-loader {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 20px;
}

#features .feature-loader .bubble {
  width: 12px;
  height: 12px;
  background-color: #1e90ff;
  border-radius: 50%;
  animation: bubble 0.6s ease-in-out infinite alternate;
}

#features .feature-loader .bubble:nth-child(2) {
  animation-delay: 0.2s;
}

#features .feature-loader .bubble:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bubble {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-12px);
  }
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
}

.feature-content h3,
.feature-content p {
  color: white;
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  background: #000;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #0d6efd;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0b5ed7;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Text Selection */
::selection {
  background: #0d6efd;
  color: white;
}

/* Loading Animation */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #0d6efd;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .ContentWrapper {
    padding: 2rem 1rem;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Enhanced Focus States */
*:focus {
  outline: 2px solid #0d6efd;
  outline-offset: 2px;
}

/* Card Hover Effects */
.card-hover-effect {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(13, 110, 253, 0.2);
}
/* General Reset for Navbar */
.navbar {
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: transparent; /* Changed from black to transparent */
  color: #fff;
}

/* Reset body margin and padding */
body {
  margin: 0;
  padding: 0;
}

/* Services Section */
#services {
  padding: 0; /* Removed top padding */
  background: linear-gradient(to right, #17263c, #1e3a5f);
  color: #fff; /* Changed all text to white */
  position: relative;
}

#services .service-desc {
  margin: 10px 10px 20px;
}

#services h2 {
  color: #fff; /* Text color to white */
}

#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff; /* Text color to white */
}

#services p {
  color: #fff; /* Text color to white */
}

#services .service-desc {
  margin-bottom: 40px;
}

/* Image Container */
.image-container {
  position: relative;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  top: 0;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  top: 0;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  padding: 30px;
}

.image-overlay h1 {
  font-size: 2.5em;
  margin-bottom: 15px;
}

.image-overlay p {
  font-size: 1.2em;
  color: white; /* Updated to white */
  -webkit-text-decoration-color: white;
          text-decoration-color: white; /* Ensure text decoration is also white */
}

/* Card Styling */
.service-card {
  background-color: #324864; /* Changed to the new color */
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3,
.service-card p {
  color: #fff; /* Update text color for better contrast */
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Grid Layout */
#services .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#services .col-md-4, #services .col-sm-6 {
  flex: 0 1 30%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Responsive Scaling */
@media (max-width: 992px) {
  #services .col-md-4, #services .col-sm-6 {
    transform: scale(0.9);
  }
}

@media (max-width: 768px) {
  #services .col-md-4, #services .col-sm-6 {
    transform: scale(0.8);
  }
}

@media (max-width: 576px) {
  #services .col-md-4, #services .col-sm-6 {
    transform: scale(0.7);
  }
}

/* Button Styling */
button {
  padding: 12px 24px;
  background-color:#3b82f6 ;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

button:hover {
  background-color: #5ca9fb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

button:active {
  background-color: #1e7e34;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.white-text {
  color: white; /* Class to make text white */
}

.service-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.top-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.overlay-text {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.service-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin-top: 20px;
  grid-gap: 20px;
  gap: 20px;
  position: relative;
}

.service-image {
  flex: 1 1;
}

.service-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  animation: slide-in-left 1.5s ease forwards;
}

.service-description {
  flex: 2 1;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: slide-in-right 1.5s ease forwards;
}

.service-not-found {
  text-align: center;
  padding: 50px;
}

.service-not-found a {
  color: #007bff;
  text-decoration: underline;
}

.related-services-scroll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
}

.service-card {
  flex: 1 0 30%; /* Flex basis for larger screens */
  max-width: 300px; /* Max width on larger screens */
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out;
  box-sizing: border-box;
}

.service-card img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 5px;
}

.show-cards .service-card {
  animation: slide-in 1s ease forwards;
}

.dot-navigation {
  text-align: center;
  margin-top: 20px;
}

.dot-navigation .dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot-navigation .dot.active {
  background-color: #333;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  .service-description {
    width: 100%;
  }
  .service-card {
    flex: 0 0 80%; /* Set width to 80% on medium screens */
    max-width: none;
  }
}

@media (max-width: 480px) {
  .service-card {
    flex: 0 0 100%; /* Full width on very small screens */
    max-width: none;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: #f6f6f6;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
/* New class for the section title with black color and border */
.section-title-black-border {
  color: black !important; /* Ensures the text is black */
  border: 2px solid black;
  padding: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 24px; /* Adjust as needed */
  border-radius: 8px; /* Optional: add rounded corners */
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-image {
  float: left;
  margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}

/* Loading State */
.loading {
  font-size: 18px;
  color: #999;
  font-style: italic;
}

/* Optional: Uncomment for Hover Effects */
.testimonial:hover {
  background: #e6e6e6;
  transform: translateY(-5px);
  transition: transform 0.3s ease, background 0.3s ease;
}

.specialized-tech-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1a1f2e 0%, #2a3444 100%);
  min-height: 100vh;
  text-align: center;
}

.section-title {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: slideDown 0.8s ease forwards;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

.tech-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.15);
}

.card-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  z-index: 2;
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.tech-card:hover .card-icon {
  transform: scale(1.2);
}

.card-image-container {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-card:hover .card-image {
  transform: scale(1.1);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.tech-card:hover .card-overlay {
  opacity: 1;
}

.stats-container {
  padding: 1rem;
  color: white;
  width: 100%;
  transform: translateY(20px);
  transition: transform 0.4s ease;
}

.tech-card:hover .stats-container {
  transform: translateY(0);
}

.stat {
  margin-bottom: 1rem;
}

.stat-label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #a0aec0;
}

.stat-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
}

.stat-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  margin: 0.5rem 0;
  overflow: hidden;
}

.stat-fill {
  height: 100%;
  background: linear-gradient(90deg, #3b82f6 0%, #60a5fa 100%);
  border-radius: 2px;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-card:hover .stat-fill {
  transform: scaleX(1);
}

.card-title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.card-description {
  color: #a0aec0;
  font-size: 1rem;
  line-height: 1.5;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom AOS animations */
[data-aos="custom-fade-up"] {
  opacity: 0;
  transform: translateY(30px);
  transition-property: transform, opacity;
}

[data-aos="custom-fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .tech-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .specialized-tech-section {
    padding: 2rem 1rem;
  }

  .tech-grid {
    grid-template-columns: 1fr;
  }

  .card-title {
    font-size: 1.25rem;
  }
}

/* Add smooth scrolling to the whole page */
html {
  scroll-behavior: smooth;
}
:root {
  --primary-color: #6C3CE9;
  --text-dark: #1A1A1A;
  --text-light: #666;
  --white: #fff;
  --mission-color: #FF5733;
  --vision-color: #33FF57;
  --work-color: #3357FF;
  --deep-blue: #0B1222;
  --star-color: #ffffff;
  --grid-color: rgba(33, 150, 243, 0.1);
  --line-color: rgba(33, 150, 243, 0.4);
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: var(--deep-blue);
  line-height: 1.6;
  color: var(--text-dark);
}

.contact-space-background {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0B1222, #1a237e);
  overflow: hidden;
}

.contact-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: 
    radial-gradient(2px 2px at 20px 30px, #eee, rgba(0,0,0,0)), 
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 50px 160px, #ddd, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0,0,0,0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: twinkle 4s ease-in-out infinite;
}

.contact-grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(90deg, var(--grid-color) 1px, transparent 1px),
    linear-gradient(0deg, var(--grid-color) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: center center;
  transform: perspective(500px) rotateX(60deg);
  transform-origin: center top;
}

.contact-gradient-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contact-line {
  position: absolute;
  width: 200%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%, 
    var(--line-color) 15%, 
    var(--line-color) 50%, 
    var(--line-color) 85%, 
    transparent 100%);
  animation: contact-moveLine 8s linear infinite;
}

.contact-line-1 { top: 20%; animation-delay: 0s; }
.contact-line-2 { top: 40%; animation-delay: -2s; }
.contact-line-3 { top: 60%; animation-delay: -4s; }
.contact-line-4 { top: 80%; animation-delay: -6s; }
.contact-line-5 { top: 90%; animation-delay: -8s; }

@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

@keyframes contact-moveLine {
  from { transform: translateX(-50%); }
  to { transform: translateX(0); }
}

.contact-container {
  position: relative;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 70px;
}

.contact-card {
  max-width: 1200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.contact-header .contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact-header .contact-subtitle {
  color: var(--text-light);
  line-height: 1.5;
}

.contact-details {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.contact-item {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.contact-item .contact-icon {
  background: #e6f0ff;
  padding: 0.75rem;
  border-radius: 50%;
  font-size: 1.25rem;
}

.contact-item .contact-label {
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #000;
}

.contact-item .contact-value {
  color: var(--text-light);
}

.contact-social-links {
  margin-top: auto;
}

.contact-social-title {
  font-weight: 500;
  margin-bottom: 1rem;
  color: #000;
}

.contact-social-icons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.contact-social-icon {
  background: none;
  border: 2px solid #3b82f6;
  padding: 0.5rem;
  font-size: 2rem;
  color: #1e3a8a;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s, color 0.3s;
}

.contact-social-icon:hover {
  background: #3b82f6;
  color: white;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.contact-form-group {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.contact-form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
}

.contact-form-group input,
.contact-form-group textarea {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.contact-form-group input:focus,
.contact-form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
}

.contact-submit-button {
  background: #3b82f6;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-submit-button:hover {
  background: #2563eb;
}

.contact-map-container {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.98);
}

.contact-map-container iframe {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 450px;
}

@media (min-width: 768px) {
  .contact-card {
    grid-template-columns: 1fr 1fr;
  }
}
.careers-section {
    padding: 20px;
  }
  
  .careers-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .careers-section h3 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .careers-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .careers-section li {
    margin-bottom: 15px;
  }
  
  .careers-section a {
    color: blue;
    text-decoration: underline;
  }
  
/* About Section Background */
:root {
  --primary-color: #6C3CE9;
  --text-dark: #1A1A1A;
  --text-light: #666;
  --white: #fff;
  --gradient-bg: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(238, 232, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
  --card-gradient: linear-gradient(71deg, #324864, #1e3b57, #0c2a42);
  --transition: all 0.3s ease;

  --mission-color: #FF5733; /* Red */
  --vision-color: #33FF57; /* Green */
  --work-color: #3357FF; /* Blue */
}
.about-background {
    background:linear-gradient(to right, #17263c, #1e3a5f);
    padding: 5rem 2rem;
    width: 100vw; /* Full viewport width */
    margin-left: calc(-50vw + 50%);
    position: relative; /* Ensure full-width background */
  }
  
  .about-section {
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 20px;
  }
  
  .gradient-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
  }
  
  .about-item {
    position: relative;
    background: var(--card-gradient);
    border-radius: 20px;
    padding: 2.5rem 2rem;
    color: var(--white);
    transition: var(--transition);
    box-shadow: 0 4px 20px inear-gradient(to right, #17263c, #1e3a5f)
  }
  
  .about-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(108, 60, 233, 0.2);
  }
  
  .about-item.mission {
    border-left: 5px solid var(--mission-color);
  }
  
  .about-item.vision {
    border-left: 5px solid var(--vision-color);
  }
  
  .about-item.work {
    border-left: 5px solid var(--work-color);
  }
  
  .about-item i {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .about-item.mission i {
    color: var(--mission-color);
  }
  
  .about-item.vision i {
    color: var(--vision-color);
  }
  
  .about-item.work i {
    color: var(--work-color);
  }
  
  .about-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--white);
  }
  
  .about-item p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
  }
  
.team {
  background: linear-gradient(45deg, #005889, #00739b, #00061e); /* Gradient background color */
  padding: 60px 0;
}

.section-title {
  text-align: center; /* Center-align the text */
  margin-bottom: 50px; /* Add some space below the section title */
}

.section-title h2 {
  font-size: 36px;
  font-weight: bold;
  color: #fff; /* Adjust the color as needed */
  margin-bottom: 20px; /* Add some space below the heading */
}

.section-title p {
  font-size: 18px;
  color: #ddd; /* Adjust the color as needed */
  max-width: 600px; /* Limit the width of the paragraph */
  margin: 0 auto; /* Center the paragraph */
}

/* Rest of your CSS */
.our-team {
  text-align: center;
  margin-bottom: 100px;
  z-index: 1;
  position: relative;
}

.our-team .pic {
  border-radius: 50%;
  overflow: hidden;
  width: 200px; /* Adjust the width to your desired size */
  height: 200px; /* Ensure height is equal to width for a perfect circle */
  margin: 0 auto; /* Center the image */
  position: relative;
}

.our-team .pic:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .pic:after { opacity: 1; }

.our-team .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the element without stretching */
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the icons */
  z-index: 2; /* Ensure the icons are on top */
  transition: opacity 0.5s ease;
}

.our-team:hover .social { 
  opacity: 1; 
}

.our-team .social li { 
  display: inline-block; 
}

.our-team .social li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  font-size: 15px;
  color: #fff;
  margin-right: 10px;
  transition: all 0.5s ease 0s;
}

.our-team .social li a:hover {
  background: #fff;
  color: #000;
}

.our-team .team-content {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px dotted #ddd;
  position: absolute;
  bottom: -70px;
  left: 0;
  z-index: -1;
  transition: all 0.5s ease 0s;
}

.our-team:hover .team-content { border: 2px dotted #00adae; }

.our-team .team-info {
  width: 100%;
  color: #464646;
  position: absolute;
  bottom: 12px;
  left: 0;
  background: #fff; /* Add white background */
  padding: 10px; /* Add padding for better spacing */
  border-radius: 10px; /* Add some border-radius for a smoother look */
}

.our-team .title {
  font-size: 20px;
  font-weight: 600;
  color: #464646;
  margin: 10px 0 5px 0;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #999;
}

:root {
  --primary-color: #6C3CE9;
  --text-dark: #1A1A1A;
  --text-light: #666;
  --white: #fff;
  --mission-color: #FF5733; 
  --vision-color: #33FF57; 
  --work-color: #3357FF;
  --deep-blue: #0B1222;
  --star-color: #ffffff;
  --grid-color: rgba(33, 150, 243, 0.1);
  --line-color: rgba(33, 150, 243, 0.4);
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: var(--deep-blue);
  line-height: 1.6;
  color: var(--text-dark);
}

/* Space Background Styles */
.space-background {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0B1222, #1a237e);
  overflow: hidden;
}

/* Stars Effect */
.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: 
    radial-gradient(2px 2px at 20px 30px, #eee, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 50px 160px, #ddd, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0,0,0,0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0,0,0,0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: twinkle 4s ease-in-out infinite;
}

/* Grid Overlay */
.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(90deg, rgba(33, 150, 243, 0.1) 1px, transparent 1px),
    linear-gradient(0deg, rgba(33, 150, 243, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: center center;
  transform: perspective(500px) rotateX(60deg);
  transform-origin: center top;
}

/* Gradient Lines */
.gradient-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.line {
  position: absolute;
  width: 200%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%,
    rgba(33, 150, 243, 0.2) 15%,
    rgba(33, 150, 243, 0.4) 50%,
    rgba(33, 150, 243, 0.2) 85%,
    transparent 100%
  );
  animation: moveLine 8s linear infinite;
}

.line-1 { top: 20%; animation-delay: 0s; }
.line-2 { top: 40%; animation-delay: -2s; }
.line-3 { top: 60%; animation-delay: -4s; }
.line-4 { top: 80%; animation-delay: -6s; }
.line-5 { top: 90%; animation-delay: -8s; }

/* Content Wrapper */


/* Hero Section Styles */
.hero {
  text-align: center;
  padding: 6rem 1rem;
  color: white;
}

.hero h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(33, 150, 243, 0.5);
}

.hero p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

/* Animations */
@keyframes twinkle {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
}

@keyframes moveLine {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0%); }
}



/* Responsive Adjustments */
@media (max-width: 768px) {
  .grid-overlay {
    transform: perspective(300px) rotateX(60deg);
  }
  
  .hero {
    padding: 4rem 1rem;
  }
}

/* Footer styling */
.footer {
  background: rgba(13, 17, 27, 1);
  color: #9CA3AF;
  padding: 40px 0;
  position: relative;
  width: 100%;
  bottom: 0;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.footer-section {
  flex: 1 1 200px; /* Adjusts section width */
  margin: 10px;
  display: flex;
  flex-direction: column;
}

/* Logo styling */
.footer-section img.footer-logo {
  width: 120px; /* Adjust size as needed */
  margin-bottom: 15px;
  align-self: flex-start;
}

/* General Text Color */
.footer-section p, 
.footer-section ul li a {
  color: #9CA3AF; /* text-gray-400 */
}

/* Heading color */
.footer-section h4 {
  color: #3b82f6; /* Set the heading color */
  font-weight: 600; /* font-semibold */
}

/* Specific Links Color */
.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a:hover {
  color: #3b82f6; /* Change link color on hover */
  text-decoration: underline;
}

.footer-section p {
  margin: 0;
}

.social-icons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.social-icons li {
  list-style: none;
}

.social-icons li a {
  color: #fff;
  font-size: 16px;
}

.footer-bottom {
  text-align: center; /* Centers the copyright text */
  margin-top: 20px;
  width: 100%;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

